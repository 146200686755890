import banner from "../../../static/Client/img.jpg"
import React from "react"
const Banner = () => {
  return (
    <div className="relative">
      <img src={banner} alt="" className="w-full h-[40vh] object-cover" />
      <div className="absolute md:top-1/2 top-1/4 md:left-1/2 transform md:-translate-x-1/2 md:-translate-y-1/2 text-center text-white z-10">
        <h2 className="md:text-4xl text-3xl font-bold text-white">
          OUR COMPANY PORTFOLIO
        </h2>
        <p className="md:text-xl text-sm mt-4">
          Glance through the projects we've successfully completed and
          implemented. From startup projects till enterprises we've worked on a
          range of projects to enable tech forwardness
        </p>
      </div>
      <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50 z-0"></div>
    </div>
  )
}

export default Banner
